<template>
  <div>
    <b-card>
      <validation-observer
        ref="form"
        v-slot="{invalid}"
      >
        <b-card v-if='!isDataLoaded'>
          <div class='text-center text-primary my-2'>
            <b-spinner class='align-middle' />
          </div>
        </b-card>

        <b-form
          v-else
          novalidate
          @submit.prevent="onSubmit"
        >
          <b-row>

            <b-col cols="12">
              <LocaleTabs
                :errors="formErrors"
                name="name"
              >
                <template
                  v-for="(i,k,idx) in languages"
                  :slot="`lang${idx}`"
                  slot-scope="slotScope"
                >
                  <ValidationProvider
                    :key="idx"
                    v-slot="{ errors }"
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid="`name.${k}`"
                    :name="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-input
                        v-model="form.name[k]"
                        :placeholder="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <b-col v-if="!isEdit"
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="flag"
                :name="$t('labels.type')"
                rules="required"
              >
                <b-form-group
                  :label="$t('labels.type')"
                >
                  <v-select
                    v-model="form.flag"
                    :dir="$store.state.appConfig.layout.direction"
                    label="label"
                    :options="championships_types"
                    :reduce="item => item.value"
                    :placeholder="$t('labels.type')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              v-if="form.flag === 'international_championship' && !isEdit"
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="team_type"
                :name="$t('labels.team_type')"
                rules="required"
              >
                <b-form-group
                  :label="$t('labels.team_type')"
                >
                  <v-select
                    v-model="form.team_type"
                    :dir="$store.state.appConfig.layout.direction"
                    label="name"
                    :options="teamsTypes"
                    :reduce="item => item.id"
                    :placeholder="$t('labels.team_type')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              v-if="form.flag === 'local_championship' && !isEdit"
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="country_id"
                :name="$t('country')"
                rules="required"
              >
                <b-form-group
                  :label="$t('country')"
                >
                  <v-select
                    v-model="form.country_id"
                    :dir="$store.state.appConfig.layout.direction"
                    label="name_label"
                    :options="countries"
                    :reduce="item => item.id"
                    :placeholder="$t('country')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              v-if="teams.length && !isEdit"
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="teams"
                :name="$t('labels.teams')"
                rules="required"
              >
                <b-form-group
                  :label="$t('labels.teams')"
                >
                  <v-select
                    v-model="form.teams"
                    multiple
                    :dir="$store.state.appConfig.layout.direction"
                    label="name_label"
                    :options="teams"
                    :reduce="item => item.id"
                    :placeholder="$t('labels.teams')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="6"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.order')"
                vid="order"
                rules="numeric"
              >
                <b-form-group
                  :label="$t('labels.order')"
                >
                  <b-form-input
                    v-model="form.order"
                    :placeholder="$t('labels.order')"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="6"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.number_of_teams')"
                vid="number_of_teams"
                rules="numeric"
              >
                <b-form-group
                  :label="$t('labels.number_of_teams')"
                >
                  <b-form-input
                    v-model="form.number_of_teams"
                    :placeholder="$t('labels.number_of_teams')"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12">
              <LocaleTabs
                :errors="formErrors"
                name="description"
              >
                <template
                  v-for="(i,k,idx) in languages"
                  :slot="`lang${idx}`"
                  slot-scope="slotScope"
                >
                  <ValidationProvider
                    :key="idx"
                    v-slot="{ errors }"
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid="`description.${k}`"
                    :name="$t('general.field_lang', { field: $t('labels.description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-textarea
                        v-model="form.description[k]"
                        :placeholder="$t('general.field_lang', { field: $t('labels.description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                        rows="4"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <b-col cols="12">
              <ValidationProvider
                v-slot="{ errors }"
                vid="image"
                :name="$t('labels.image')"
                rules="required"
              >
                <ImagePreview
                  v-model="image"
                  :label="$t('labels.image')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-col>

            <!-- checkbox -->
            <b-col cols="12">
              <b-card-text class="mb-0">
                {{ $t('labels.status') }}
              </b-card-text>
              <b-form-checkbox
                v-model="form.status"
                checked="true"
                value="active"
                unchecked-value="inactive"
                name="check-button"
                switch
              />
            </b-col>

            <!-- submit and reset -->
            <b-col cols="12 text-right">
              <LoadingButton />
              <b-button
                type="reset"
                variant="outline-secondary"
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  data() {
    return {
      isDataLoaded : false,
      image: null,
      teams: [],
      championships_types: [],
      countries: [],
      form: {
        name: {
          en: null,
          ar: null,
        },
        teams: null,
        flag: null,
        team_type: null,
        country_id: null,
        description: {
          en: null,
          ar: null,
        },
        image: null,
        order: null,
        number_of_teams: null,
        status: 'active',
      },
    }
  },
  computed: {
    teamsTypes() {
      return [
        {
          id: 'national_team',
          name: this.$t('labels.national_team'),
        },
        {
          id: 'local_team',
          name: this.$t('labels.local_team'),
        },
      ]
    },
  },
  watch: {
    image(val) {
      if (val) {
        this.form.image = val
      } else {
        this.form.image = 'delete'
      }
    },
    'form.team_type': function (val) {
      if (!this.isEdit) {
        this.form.teams = []
      }
      if (val !== 'local_team') {
        this.getTeams('?flag=international_championship')
      } else {
        this.getTeams('')
      }
    },
    'form.country_id': function (val) {
      this.getTeams(`?country_id=${val}`)
    },
  },
  created() {
    this.getChampionshipsTypes()
    this.getCountries()
    if (this.isEdit) {
      this.loadData()
    } else {
      this.isDataLoaded = true
    }
  },
  methods: {
    getTeams(val) {
      this.axios.get(`/lists/Team${val}`)
        .then(res => {
          this.teams = res.data
        })
    },
    getChampionshipsTypes() {
      this.axios.get('/lists/championship/types')
        .then(res => {
          this.championships_types = res.data
        })
    },
    getCountries() {
      this.axios.get('/lists/Country')
        .then(res => {
          this.countries = res.data
        })
    },
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            name: data.name,
            team_type: data.team_type,
            description: data.description,
            flag: data.flag,
            country_id: data.country_id,
            teams: data.teams.map(item => item.id),
            status: data.status,
            order: data.order,
            number_of_teams: data.number_of_teams
          }
          this.image = data.image
        })
        .catch(() => {
          this.isDataLoaded = true
        })
        .finally(() => {
          this.isDataLoaded = true
        });

    },
    loadFormData() {
      let $form = _.cloneDeep(this.form)
      $form = {
        ...$form,
      }
      if (!($form.image instanceof File)) {
        delete $form.image
      }
      return $form
    },
  },

}
</script>
